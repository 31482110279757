<template>
  <section>
    <loading
      :active="loader"
      :is-full-page="fullPage"
      :opacity="0.9"
      color="#ff6b00"
      :height="height"
      :width="width"
    />
  </section>
</template>

<script>
import Loading from "vue-loading-overlay";
import axios from "axios";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loader: false,
    };
  },
  created() {},
  mounted() {
    this.loader = true;
    //to clear local storage
    sessionStorage.clear();
    console.log(this.$route.query.user_id);
    this.AuthenticateEvolet(this.$route.query.user_id);
  },
  methods: {
    async AuthenticateEvolet(user_id) {
      const responseData = await axios({
        url: `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/evolet-login-confirmation?user_id=${user_id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });
      console.log(responseData.data);
      if (responseData.data.status_code === 200) {
        this.loader = false;

        sessionStorage.setItem(
          "access_token",
          responseData.data.data[0].access_token
        );
        this.$store.commit(
          "access_token",
          responseData.data.data[0].access_token
        );
        sessionStorage.setItem("user_id", responseData.data.data[0].user_id);
        sessionStorage.setItem(
          "complete_registration",
          responseData.data.data[0].complete_registration
        );
        sessionStorage.setItem("is_kyc", responseData.data.data[0].is_kyc);
        this.$store.commit(
          "complete_registration",
          responseData.data.data[0].complete_registration
        );
        this.$setLocalStorage(
          "is_partner",
          responseData.data.data[0].is_partner
        );
        this.$setLocalStorage("logo", responseData.data.data[0].logo);
        this.$setLocalStorage("is_logo", responseData.data.data[0].is_logo);
        this.$setLocalStorage(
          "partner_type",
          responseData.data.data[0].partner_type
        );
        sessionStorage.setItem(
          "is_payment_gateway",
          responseData.data.data[0].is_payment_gateway
        );
        sessionStorage.setItem(
          "supported_payment_modes",
          responseData.data.data[0].supported_payment_modes
        );
        sessionStorage.setItem(
          "is_check_pin",
          responseData.data.data[0].is_check_pin
        );
        sessionStorage.setItem(
            "is_self_beneficiary",
            responseData.data.data[0].is_self_beneficiary ? true : false
          );
        sessionStorage.setItem(
          "refresh_token",
          responseData.data.data[0].refresh_token
        );
        if (responseData.data.data[0].user_type) {
          sessionStorage.setItem(
            "user_type",
            responseData.data.data[0].user_type
          );
        }
        console.log("dev testing");
        this.$router.push({ name: "HomeView" });
      }
    },
  },
};
</script>

<style></style>
